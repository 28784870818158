import React, { useEffect, useState } from 'react';
import { useDropzone, type FileRejection } from 'react-dropzone';
import { type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentsUploader } from '@airhelp/react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormErrorIcon,
} from '@chakra-ui/react';
import { useWindowSize } from 'hooks/useWindowSize';

const DEFAULT_SUPPORTED_FILES = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
};

const MAX_FILE_SIZE_HUHMAN = 25;
const MAX_FILE_SIZE = MAX_FILE_SIZE_HUHMAN * 1000000;
const MAX_FILES_COUNT = 10;

const AirDocumentsUploader: React.FC<
  AirDocumentsUploaderProps & { dataTestId?: string }
> = ({
  documents,
  onDrop,
  multipleFiles,
  onDelete,
  isLoading,
  label,
  error,
  isInvalid,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const [uploaderWidth, setUploaderWidth] = useState<string>();
  const windowSize = useWindowSize();

  const {
    isDragActive,
    isFileDialogActive,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: DEFAULT_SUPPORTED_FILES,
    disabled: isLoading,
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_FILES_COUNT,
    minSize: 1,
    multiple: multipleFiles,
  });

  useEffect(() => {
    setUploaderWidth(undefined);
  }, [windowSize]);

  const useGetErrorMessage = (errorCode?: string): string => {
    switch (errorCode) {
      case undefined:
        return '';
      case 'file-too-large':
        return t('documents_uploader.errors.file_size_too_large', {
          file_size: MAX_FILE_SIZE_HUHMAN,
        });
      case 'too-many-files':
        return t('documents_uploader.errors.too_many_files');
      case 'file-invalid-type':
        return t('documents_uploader.errors.file_invalid_type');
      case 'generic':
      default:
        return t('documents_uploader.errors.generic');
    }
  };

  const selectText = (
    <Box
      dangerouslySetInnerHTML={{
        __html: t('documents_uploader.drag_and_drop'),
      }}
      fontSize="lg"
      textAlign="center"
      color="primary.900"
    />
  );

  const showErrors = isInvalid || Boolean(fileRejections.length);
  return (
    <FormControl
      style={{ width: uploaderWidth }}
      isInvalid={showErrors}
      minHeight={{ base: 'auto', lg: '304px' }}
    >
      <FormLabel fontSize="lg" fontWeight="medium" mb={3}>
        {label}
      </FormLabel>
      <Box width="100%">
        <DocumentsUploader
          loadingText={t('documents_uploader.uploading')}
          selectText={selectText}
          supportedFilesText={t(
            'documents_uploader.supported_files_with_size',
            { file_size: MAX_FILE_SIZE_HUHMAN },
          )}
          uploadText={t('documents_uploader.upload_file')}
          dropText={t('documents_uploader.drop')}
          isLoading={Boolean(isLoading)}
          getErrorMessage={useGetErrorMessage}
          onDelete={onDelete}
          getInputProps={getInputProps()}
          getRootProps={getRootProps()}
          fileRejections={fileRejections as FileRejection[]}
          acceptedFiles={documents}
          isDropzoneActive={isDragActive || isFileDialogActive}
          isInvalid={isInvalid}
          dataTestId={dataTestId}
        />
        <FormErrorMessage data-testid="uploader-error">
          <FormErrorIcon
            position="absolute"
            top="46px"
            right={4}
            color="danger.700"
            fill="danger.700"
          />
          {error?.message}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
};

export interface AirDocumentsUploaderProps {
  documents: File[];
  onDelete?: (file: File) => void;
  onDrop: (files: File[]) => void;
  isLoading?: boolean;
  multipleFiles?: boolean;
  label: string;
  error?: FieldError;
  isInvalid?: boolean;
}

export default AirDocumentsUploader;
