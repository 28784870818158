import {
  Box,
  Button,
  type ButtonProps,
  Flex,
  Text,
  Link as ChakraLink,
  type LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { Info } from '@airhelp/icons';
import React, { type PropsWithChildren } from 'react';
import { Link } from 'react-router';

const VARIANT_STYLES = {
  info: {
    backgroundColor: 'primary.400',
    foregroundColor: 'primary.200',
  },
  success: {
    backgroundColor: 'success.500',
    foregroundColor: 'success.200',
  },
  warning: {
    backgroundColor: 'warning.400',
    foregroundColor: 'warning.200',
  },
  danger: {
    backgroundColor: 'danger.400',
    foregroundColor: 'danger.200',
  },
};

export default function CallToAction({
  variant = 'info',
  actions,
  children,
}: PropsWithChildren<{
  variant?: 'info' | 'success' | 'warning' | 'danger';
  actions?: React.ReactNode;
}>) {
  return (
    <Flex
      rounded="xl"
      border="2px solid"
      borderColor={VARIANT_STYLES[variant].backgroundColor}
      p={4}
      pos="relative"
      bg="white"
      align="center"
    >
      <Box
        minW={8}
        minH={8}
        borderRadius="full"
        backgroundColor={VARIANT_STYLES[variant].foregroundColor}
        pos="relative"
      >
        <Info
          w={5}
          h={5}
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          color={VARIANT_STYLES[variant].backgroundColor}
        />
      </Box>
      <Flex
        direction={{
          base: 'column',
          md: 'row',
        }}
        w="full"
        gap={{
          base: 2,
          md: 0,
        }}
        align={{
          base: 'start',
          md: 'center',
        }}
        ml={4}
      >
        <Text color="primary.900" fontSize="sm">
          {children}
        </Text>
        <Box
          flex={1}
          display={{
            base: 'none',
            md: 'block',
          }}
        />
        <Flex gap={2} direction={{ base: 'row-reverse', md: 'row' }}>
          {actions}
        </Flex>
      </Flex>
    </Flex>
  );
}

export function CallToActionButton({
  children,
  subtle,
  ...props
}: PropsWithChildren<
  {
    subtle?: boolean;
  } & ButtonProps
>) {
  return (
    <Button
      variant={subtle ? 'subtle' : 'secondary'}
      size="sm"
      fontSize={{ base: 'xs', md: 'sm' }}
      {...props}
    >
      {children}
    </Button>
  );
}

export function CallToActionLinkButton({
  children,
  href,
  variant = 'secondary',
  ...props
}: PropsWithChildren<
  {
    subtle?: boolean;
  } & ChakraLinkProps
>) {
  return (
    <ChakraLink as={Link} to={href} {...props}>
      <Button
        variant={variant}
        size="sm"
        fontSize={{ base: 'xs', md: 'sm' }}
        pointerEvents="none"
      >
        {children}
      </Button>
    </ChakraLink>
  );
}
