import React from 'react';
import { fontFamilyFraktion } from '@airhelp/design-tokens';
import { ShieldFilled } from '@airhelp/icons';
import { AirhelpPlus } from '@airhelp/react';
import { HStack, Text, type TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const badgeTextStyles: TextProps = {
  textTransform: 'uppercase',
  color: 'greyscale.700',
  fontWeight: '700',
  fontSize: '11px',
  fontFamily: fontFamilyFraktion,
};

interface IComponent {
  isProtected?: boolean;
}

const ProtectedBadge: React.FC<IComponent> = ({ isProtected }) => {
  const { t } = useTranslation();

  const label = isProtected
    ? t('common.protected_by')
    : t('common.not_protected_by');

  return (
    <HStack gap={1} data-testid="protected-by-badge-b2c">
      {isProtected ? <ShieldFilled color="success.500" boxSize={5} /> : null}
      <Text {...badgeTextStyles}>{label}</Text>
      <AirhelpPlus width="56px" height="16px" />
    </HStack>
  );
};

export default ProtectedBadge;
