import {
  useSimplifiedClaimSuspense,
  useClaimSuspense,
} from '@airhelp/webapp/react';
import { useClaimState } from 'hooks/useClaimState';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';

export function useCompensationData({ claimId }: { claimId: number }) {
  const { locale } = useLocale();

  const { data: claimData } = useSimplifiedClaimSuspense({
    params: { id: Number(claimId), locale },
  });

  const { data: claim, isLoading } = useClaimSuspense({
    params: { id: claimId },
  });

  const { canDisplayCompensation } = useClaimState(claimId);

  const payoutValues = claimData?.compensation;

  const totalPassengers = claimData?.fellowPassengers.length + 1; // include the claimant

  const claimCompensation = Number(payoutValues?.totalAmount); // base compensation that we got from airline
  const legalFee = Number(payoutValues?.legalActionFee);
  const serviceFee = Number(payoutValues?.serviceFee);
  const finalCompensation = Number(payoutValues?.expectedPayoutAmount); // final compensation that we will get after legal and service fees are deducted

  const currency = payoutValues?.currency ?? 'eur';

  const finalCompensationPerPassenger = finalCompensation / totalPassengers;

  const formatPayoutValues = (value: number) => {
    const localeMapper = {
      'zh-CN': 'zh',
      'ar-SA': 'sa',
      'en-int': 'en',
      'en-INT': 'en',
    };

    const formattedLocale = localeMapper[locale] ?? locale;

    return Intl.NumberFormat(formattedLocale as string, {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 3,
      minimumIntegerDigits: value > 0 ? 1 : 2,
      signDisplay: 'negative',
    }).format(value);
  };

  const formattedValues = {
    legalFee: formatPayoutValues(legalFee),
    serviceFee: formatPayoutValues(serviceFee),
    claimCompensation: formatPayoutValues(claimCompensation),
    finalCompensation: formatPayoutValues(finalCompensation),
    finalCompensationPerPassenger: formatPayoutValues(
      finalCompensationPerPassenger,
    ),
  };

  return {
    currency: payoutValues?.currency,
    freeOfCharge: Boolean(claim.claimPayout?.freeOfCharge),
    formattedValues,
    legalFee,
    serviceFee,
    claimCompensation,
    canDisplayCompensation: Boolean(canDisplayCompensation),
    finalCompensation,
    finalCompensationPerPassenger,
    isLoading,
    isClosed: claimData.state.identifier === 'closed',
  };
}
