import React from 'react';
import { useTranslation } from 'react-i18next';
import { fontFamilyFraktion } from '@airhelp/design-tokens';
import { Text, type TextProps } from '@chakra-ui/react';
import { type Journey } from 'models/journey/Journey';
import ProtectedBadge from './ProtectedBadge/ProtectedBadge';

const badgeTextStyles: TextProps = {
  textTransform: 'uppercase',
  color: 'greyscale.700',
  fontWeight: '700',
  fontSize: '11px',
  fontFamily: fontFamilyFraktion,
};

interface IComponent {
  journey: Journey;
}

const ProtectionBadge: React.FC<IComponent> = ({ journey }) => {
  const { t } = useTranslation();

  return journey.journeyProtected ? (
    <ProtectedBadge isProtected />
  ) : (
    <Text {...badgeTextStyles} data-testid="not-protected-b2c">
      {t('common.not_protected')}
    </Text>
  );
};

export default ProtectionBadge;
