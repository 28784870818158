import React from 'react';
import { useTranslation } from 'react-i18next';
import { RejectionReason } from '@airhelp/plus';
import { type Journey } from 'models/journey/Journey';
import girlInPlante from 'assets/images/girl-in-a-plane@2x.png';
import planteInTheClouds from 'assets/images/plane-in-the-clouds@2x.png';
import BaseModal from './BaseModal';
import NoMembershipModal from './NoMembershipModal';

interface IComponent {
  journey: Journey;
}

const ProtectionModals: React.FC<IComponent> = ({ journey }) => {
  const { t } = useTranslation();

  const { airPayout, airLuggage } = journey.protections;
  const journeyProtected = airPayout?.protected || airLuggage?.protected;

  const noMembershipAccount =
    journey.rejections.general === RejectionReason.NO_MEMBERSHIP;

  const noCredits =
    journey.rejections.general === RejectionReason.NO_CREDITS_LEFT;

  if (!journeyProtected && noMembershipAccount) {
    return <NoMembershipModal journey={journey} />;
  }

  const modalContent = journeyProtected
    ? {
        image: girlInPlante,
        header: t('journeys.protection_modal.protection_title'),
        caption: t('journeys.protection_modal.protection_subtitle'),
      }
    : noCredits
      ? {
          image: planteInTheClouds,
          header: t('journeys.protection_modal.trip_added_limited'),
          caption: t('journeys.protection_modal.plan_limit_reached'),
        }
      : {
          image: planteInTheClouds,
          header: t('journeys.protection_modal.trip_added_limited'),
          caption: t('journeys.protection_modal.not_applicable'),
        };

  return (
    <BaseModal
      image={modalContent.image}
      header={modalContent.header}
      caption={modalContent.caption}
      journey={journey}
    />
  );
};

export default ProtectionModals;
