import { Expose, plainToClass } from 'class-transformer';
import {
  type AirRejections,
  type FetchJourneyResponse as IFetchJourneyResponse,
  type Itinerary,
  AirBundleRejectionReason,
  type Trip,
  type Protections,
  type Rejections,
} from '@airhelp/plus';
import { type TFunction } from 'i18next';
import { type AirPayoutJourney } from './AirPayoutJourney';
import { type AirLuggageJourney } from './AirLuggageJourney';

export class Journey implements IFetchJourneyResponse {
  id: number;
  name: string;
  active: boolean;
  loungeActive: boolean;
  airPayoutJourneys: AirPayoutJourney[];
  airLuggageJourneys: AirLuggageJourney[];
  airRejections: AirRejections;
  displayAirBundleActionNotification: boolean;
  itineraries: [Itinerary, ...Itinerary[]];
  trip: Trip | null;
  protections: Protections;
  rejections: Rejections;

  @Expose()
  get journeyProtected(): boolean {
    return Object.values(this.protections).some(
      (protection) => protection?.protected,
    );
  }

  @Expose()
  get airPayoutRejected(): boolean {
    return this.airRejections.airPayout === AirBundleRejectionReason.REJECTED;
  }

  @Expose()
  get airLuggageRejected(): boolean {
    return this.airRejections.airLuggage === AirBundleRejectionReason.REJECTED;
  }

  @Expose()
  get airPayoutNoCredits(): boolean {
    return this.airRejections.airPayout === AirBundleRejectionReason.NO_CREDITS;
  }

  @Expose()
  get airLuggageNoCredits(): boolean {
    return (
      this.airRejections.airLuggage === AirBundleRejectionReason.NO_CREDITS
    );
  }

  @Expose()
  get mainAirPayoutJourney(): AirPayoutJourney {
    return this.airPayoutJourneys[0];
  }

  @Expose()
  get mainAirLuggageJourney(): AirLuggageJourney {
    return this.airLuggageJourneys[0];
  }

  generateDisplayName(t: TFunction, locale: string): string {
    if (this.name) {
      return this.name;
    }
    const firstItinerary = this.itineraries[0];
    const lastFlight =
      firstItinerary.flights[firstItinerary.flights.length - 1];
    const destinationAirport = lastFlight.arrivalAirport;

    return t('journeys.name_default', {
      destination:
        destinationAirport?.cityTranslations[locale] ||
        destinationAirport?.city,
    });
  }
}

export function transformToFetchJourneyClass(
  journey: IFetchJourneyResponse,
): Journey {
  return plainToClass(Journey, journey);
}
