import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import JourneyFlightCard from 'components/Journey/JourneyFlightCard';
import ProtectedBadge from 'elements/ProtectionBadge/ProtectedBadge/ProtectedBadge';
import clearLocationHistory from 'utils/clearLocationHistory';
import { type Journey } from 'models/journey/Journey';

interface IComponent {
  image: string;
  header: string;
  caption: string;
  journey: Journey;
}

const BaseModal: React.FC<IComponent> = ({
  image,
  header,
  caption,
  journey,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const { airPayout, airLuggage } = journey.protections;
  const journeyProtected = airPayout?.protected || airLuggage?.protected;

  const handleClose = () => {
    clearLocationHistory(navigate);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxWidth="390px" borderTopRadius="xl">
        <Center height="152px">
          <Image src={image} alt="air-help-protection-image" />
          <ModalCloseButton
            borderRadius="xl"
            width="28px"
            height="28px"
            top={4}
            right={4}
            backgroundColor="greyscale.300"
            sx={{
              svg: { boxSize: 2 },
              color: 'greyscale.600',
              _hover: { backgroundColor: 'primary.200' },
              _active: {
                backgroundColor: 'primary.800',
                color: 'greyscale.200',
              },
            }}
            data-testid="modal-close-button"
          />
        </Center>
        <VStack gap={0} padding={{ base: 4, md: 6 }}>
          <VStack gap={3} pb={8}>
            <Heading
              title="Your trip is protected with AirHelp+"
              textAlign="center"
              variant="h4"
            >
              {header}
            </Heading>
            <Text fontSize="sm" fontWeight="bold" color="greyscale.600">
              {caption}
            </Text>
          </VStack>
          <VStack
            gap={2}
            width="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            padding={2}
            backgroundColor="greyscale.300"
            borderRadius="xl"
          >
            <Box
              width="100%"
              backgroundColor="greyscale.100"
              borderRadius="xl"
              padding={5}
            >
              <JourneyFlightCard journey={journey} />
            </Box>
            <Box px={5} py={2} width="100%">
              <ProtectedBadge isProtected={journeyProtected} />
            </Box>
          </VStack>
          <Button
            onClick={handleClose}
            width="100%"
            variant="primary"
            size="md"
            fontSize="sm"
            fontWeight="medium"
            mt={4}
            data-testid="action-button"
          >
            {t('common.ok')}
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default BaseModal;
