import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink, useNavigate } from 'react-router';
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  ListItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Check } from '@airhelp/icons';
import JourneyFlightCard from 'components/Journey/JourneyFlightCard';
import clearLocationHistory from 'utils/clearLocationHistory';
import { type Journey } from 'models/journey/Journey';
import girlWithLuggage from 'assets/images/girl-with-luggage2x.png';
import { PAGE_PATHS } from 'config/routes';
import ProtectedBadge from 'elements/ProtectionBadge/ProtectedBadge';

interface IComponent {
  journey: Journey;
}

const NoMembershipModal: React.FC<IComponent> = ({ journey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleClose = () => {
    clearLocationHistory(navigate);
    onClose();
  };

  const benefits = [
    {
      text: t('journeys.protection_modal.compensation_cancelled_delayed', {
        amount: 100,
      }),
    },
    {
      text: t('journeys.protection_modal.compensation_luggage', {
        amount: 100,
      }),
    },
    {
      text: t('journeys.protection_modal.compensation_lounge_access'),
    },
    {
      text: t('journeys.protection_modal.compensation_up_to', { amount: 600 }),
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxWidth="390px" borderTopRadius="xl">
        <Center height="152px">
          <Image
            src={girlWithLuggage}
            alt="air-help-protection-image"
            borderTopRadius="xl"
          />
          <ModalCloseButton
            borderRadius="xl"
            width="28px"
            height="28px"
            top={4}
            right={4}
            backgroundColor="greyscale.300"
            sx={{
              svg: { boxSize: 2 },
              color: 'greyscale.600',
              _hover: { backgroundColor: 'primary.200' },
              _active: {
                backgroundColor: 'primary.800',
                color: 'greyscale.200',
              },
            }}
            data-testid="modal-close-button"
          />
        </Center>
        <VStack gap={0} padding={{ base: 4, md: 6 }}>
          <VStack gap={3} pb={8}>
            <Heading
              title={t('journeys.protection_modal.offer_protection')}
              variant="h4"
            >
              <Trans
                i18nKey="journeys.protection_modal.offer_protection"
                components={{
                  span: <Text as="span" variant="h4" color="primary.500" />,
                }}
              />
            </Heading>
            <Text
              fontSize="sm"
              fontWeight="medium"
              color="greyscale.600"
              width="100%"
            >
              <Trans
                i18nKey="journeys.protection_modal.trusted_by_millions"
                components={{
                  span: (
                    <Text
                      as="span"
                      fontSize="sm"
                      fontWeight="medium"
                      color="primary.500"
                    />
                  ),
                }}
              />
            </Text>
            <UnorderedList spacing={2} listStyleType="disc" m={0} width="100%">
              {benefits.map((benefit) => (
                <ListItem
                  display="flex"
                  alignItems="flex-start"
                  color="greyscale.700"
                  key={benefit.text}
                >
                  <Check boxSize={5} me={2} color="success.500" />
                  <Text fontSize="sm" fontWeight="medium">
                    {benefit.text}
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </VStack>
          <VStack
            gap={2}
            width="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            padding={2}
            backgroundColor="greyscale.300"
            borderRadius="xl"
          >
            <Box
              width="100%"
              backgroundColor="greyscale.100"
              borderRadius="xl"
              padding={5}
            >
              <JourneyFlightCard journey={journey} />
            </Box>
            <Box px={5} py={2} width="100%">
              <ProtectedBadge />
            </Box>
          </VStack>
          <Button
            onClick={handleClose}
            as={ReactLink}
            width="100%"
            variant="primary"
            size="md"
            fontSize="sm"
            fontWeight="medium"
            mt={4}
            to={{
              pathname: generatePath(
                PAGE_PATHS.MY_ACCOUNT.PLAN_MANAGEMENT_PAGE,
              ),
            }}
            data-testid="action-button"
          >
            {t('common.check_our_plans')}
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default NoMembershipModal;
